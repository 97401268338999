import React from 'react';
import { Box, Flex, Text, Button, Heading } from 'rebass';
import Page from './ui/Page';

const ThemeDemoPage = () => {
    const paletteColorTypes = ['text', 'headerText', 'background', 'header', 'primary'];

    return (
        <Page>
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
                height="100%"
            >
                <Flex flexDirection={['column', 'row']} bg="white">
                    {paletteColorTypes.map(colorType => (
                        <Flex
                            key={colorType}
                            flexDirection="column"
                            alignItems="center"
                            color="black"
                        >
                            <Box
                                width="100px"
                                height="100px"
                                backgroundColor={colorType}
                                m={2}
                            />
                            {colorType}
                        </Flex>
                    ))}
                </Flex>

                <Heading variant="display" color="heading">
                    Heading text
                </Heading>
                <Box>
                    <Text fontSize={[1, 2, 3]}>This is some text [1, 2, 3]</Text>
                    <Text fontSize={[2, 3, 4]}>This is some text [2, 3, 4]</Text>
                    <Text fontSize={[3, 4, 5]}>This is some text [3, 4, 5]</Text>
                </Box>
                <Box>
                    <Button variant="primary" mr={2}>
                        Primary
                    </Button>
                    <Button variant="outline" mr={2}>
                        Outline
                    </Button>
                </Box>
            </Flex>
        </Page>
    );
};

export default ThemeDemoPage;
