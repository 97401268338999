import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import { Hide } from '@rebass/hide';
import { ThemeProvider } from 'emotion-theming';
import rtheme from '@rebass/preset';
import Header from './nav/Header';
import SideDrawer from './nav/SideDrawer';
import PageRoutes from './nav/PageRoutes';
import ThemeControls from './ThemeControls';
import getAppTheme from '../theme/getAppTheme';
import colorPalettes from '../theme/colorPalettes';
import UnicornModeToggle from './ui/UnicornModeToggle';
import { defaultColorPalette, ModeContext } from '../theme';

const AppLayout = () => {
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
    const [colorKey, setColorKey] = useState(defaultColorPalette);
    const history = useHistory();

    const theme = rtheme;
    const colorPalette = colorPalettes[colorKey] || {};

    const isUnicornMode = colorKey === 'unicorn';

    const onUnicornChange = e =>
        setColorKey(e.target.checked ? 'unicorn' : defaultColorPalette);

    const controls = (
        <div>
            <Box
                sx={{
                    position: 'fixed',
                    right: '7px',
                    bottom: '2px',
                    zIndex: 80,
                }}
            >
                {history.location.pathname === '/demo' && (
                    <ThemeControls mode={colorKey} onColorPaletteChange={setColorKey} />
                )}
                <Hide xsmall small>
                    <UnicornModeToggle
                        defaultChecked={isUnicornMode}
                        onChange={onUnicornChange}
                    />
                </Hide>
            </Box>
        </div>
    );

    return (
        <ThemeProvider theme={getAppTheme(theme, colorPalette, colorKey)}>
            <ModeContext.Provider value={colorKey}>
                <Flex flexDirection="column">
                    <SideDrawer
                        open={sideDrawerOpen}
                        onClose={() => setSideDrawerOpen(false)}
                        isUnicornMode={isUnicornMode}
                        onUnicornChange={onUnicornChange}
                    />
                    <Header onDrawerToggle={() => setSideDrawerOpen(true)} />
                    {controls}
                    <PageRoutes />
                </Flex>
            </ModeContext.Provider>
        </ThemeProvider>
    );
};

export default AppLayout;
