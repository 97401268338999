// import inspoColors from '../assets/colors.json';

// const createColorPalettes = colors => {
//     let i = 0;

//     return colors.reduce(
//         (acc, color) => ({
//             ...acc,
//             [++i]: {
//                 text: color[0],
//                 background: color[3],
//                 primary: color[2],
//                 secondary: color[1],
//             },
//         }),
//         {}
//     );
// };

const myPaletts = {
    wix: {
        text: '#422a08',
        background: '#f1f1f1',
        primary: '#b13dac',
        secondary: '#4ca1ea',
        header: '#f1f1f1',
    },
    sumSolstice: {
        text: '#ECCC43',
        background: '#18414C',
        header: '#FEFDEB',
        primary: '#AF8969',
        secondary: '#AF8969',
        headerText: '#AF8969',
    },
    sumSolstice2: {
        text: '#4a3b2d',
        background: '#ebead8',
        header: '#2D464B',
        primary: '#AF8969',
        secondary: '#AF8969',
        heading: '#2D464B',
    },
    summerCampy: {
        text: '#FACE59',
        background: '#085F63',
        header: '#085F63',
        primary: '#FD5959',
        secondary: '#FD5959',
        headerText: '#49beb7',
    },
    unicorn1: {
        text: '#2CF4F1',
        background: '#F4F417',
        header: '#F31891',
        primary: '#FD5959',
        headerText: '#49beb7',
    },
    unicorn: {
        text: '#6c6ca8',
        background: '#FFFFFF',
        header: '#FFB3F8',
        primary: '#C79FF0',
        headerText: 'green',
    },
};

// export default { ...createColorPalettes(inspoColors), ...myPaletts };
export default myPaletts;
