import React from 'react';
import { Flex } from 'rebass';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';

const GoogleForm = props => {
    const { url } = props;

    return (
        <Flex
            height={['2800px', '2640px', '2620px']}
            margin="auto"
            justifyContent="center"
        >
            <Iframe width="100%" height="100%" url={url} frameBorder="0" />
        </Flex>
    );
};

GoogleForm.propTypes = {
    url: PropTypes.string.isRequired,
};

export default GoogleForm;
