import pika1 from '../../../assets/sounds/pika1.wav';
import pika2 from '../../../assets/sounds/pika2.wav';
import pika3 from '../../../assets/sounds/pika3.mp3';
import pika4 from '../../../assets/sounds/pika4.mp3';
import pika5 from '../../../assets/sounds/pika5.mp3';
import pika6 from '../../../assets/sounds/pika6.mp3';
import pika7 from '../../../assets/sounds/pika7.mp3';
import pika8 from '../../../assets/sounds/pika8.mp3';
import pika9 from '../../../assets/sounds/pika9.mp3';
import pika10 from '../../../assets/sounds/pika10.mp3';
import pika11 from '../../../assets/sounds/pika11.mp3';
import pika12 from '../../../assets/sounds/pika12.mp3';
import pika13 from '../../../assets/sounds/pika13.mp3';
import pika14 from '../../../assets/sounds/pika14.mp3';
import pika15 from '../../../assets/sounds/pika15.mp3';
import pika16 from '../../../assets/sounds/pika16.mp3';
import pika17 from '../../../assets/sounds/pika17.mp3';
import pika18 from '../../../assets/sounds/pika18.mp3';

export default [
    pika1,
    pika2,
    pika3,
    pika4,
    pika5,
    pika6,
    pika7,
    pika8,
    pika9,
    pika10,
    pika11,
    pika12,
    pika13,
    pika14,
    pika15,
    pika16,
    pika17,
    pika18,
];
