import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import ReactToggle from 'react-toggle';
import RainbowText from 'react-rainbow-text';
import Image from '../Image';
import toggleUnicorn from '../../../assets/toggle-unicorn.png';
import './UnicornModeToggle.css';

const RainbowWrapper = ({ children, ...props }) => (
    <Box fontSize={3} {...props}>
        <RainbowText lightness={0.5}>{children}</RainbowText>
    </Box>
);
RainbowWrapper.propTypes = { children: PropTypes.node.isRequired };

const UnicornModeToggle = props => {
    const { onChange, defaultChecked } = props;
    const [isChecked, setChecked] = useState(defaultChecked);

    const baseImgStyle = {
        position: 'absolute',
        top: '5px',
        width: '14px',
        height: '14px',
        zIndex: 90,
        pointerEvents: 'none',
    };
    const checkedStyle = {
        ...baseImgStyle,
        right: '5px',
    };
    const uncheckedStyle = {
        ...baseImgStyle,
        left: '4px',
    };

    return (
        <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="76px"
            width="92px"
        >
            {isChecked && <RainbowWrapper paddingBottom="2px">UNICORN</RainbowWrapper>}
            <div style={{ position: 'relative' }}>
                <Image
                    src={toggleUnicorn}
                    sx={isChecked ? checkedStyle : uncheckedStyle}
                />
                <ReactToggle
                    {...props}
                    icons={false}
                    onChange={event => {
                        const { checked } = event.target;
                        setChecked(checked);

                        if (onChange) {
                            onChange(event);
                        }
                    }}
                />
            </div>
            {isChecked && <RainbowWrapper>MODE</RainbowWrapper>}
        </Flex>
    );
};

UnicornModeToggle.propTypes = {
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
};

UnicornModeToggle.defaultProps = {
    onChange: null,
    defaultChecked: false,
};

export default UnicornModeToggle;
