import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from '../../../hooks';
import './PhotoSpinner.css';

const photoViewTime = 4000;

const PhotoSpinner = props => {
    const { photos } = props;
    const [photoIndex, setPhotoIndex] = useState(0);

    const tickPhoto = () => {
        const incrementIndex = (photoIndex + 1) % photos.length;
        setPhotoIndex(incrementIndex);
    };

    useInterval(tickPhoto, photoViewTime);

    return (
        <div className="photo-spinner">
            <img className="photo-spinner-img" alt="spinner" src={photos[photoIndex]} />
        </div>
    );
};

PhotoSpinner.propTypes = {
    photos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PhotoSpinner;
