import React from 'react';
import { Box, Flex, Text } from 'rebass';
import Page from '../ui/Page';
import PhotoSpinner from './PhotoSpinner';
import Schedule from './Schedule';
import OpenBarDude from './OpenBarDude';
import Covid from './Covid';

const date = 'Saturday, June 12th, 2021';
const location = 'Holiday Hill Day Camp';
const address = '41 Chaffeeville Rd, Mansfield Center, CT 06250';

const HomePage = () => (
    <Page>
        <Flex
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            marginTop={('0', '0', '100px')}
        >
            <Covid />
            <PhotoSpinner />
            <Text fontSize={[2, 3, 4]}>Please join us at</Text>
            <Text fontSize={[3, 4, 5]}>{location}</Text>
            <Text fontSize={[2, 3, 4]}>on {date}</Text>
            <Text fontSize={[2, 3, 4]}>{address}</Text>
            <Box m={2}>
                <OpenBarDude />
            </Box>
            <Text m={3} fontSize={[2, 3, 4]}>
                Both the ceremony and reception will be held at the same location. Staying
                at the Inn on Storrs or the Graduate Storrs? We’ll have shuttles running
                from the venue to those hotels before the ceremony and at the end of the
                reception. Feel free to leave the driving to us!
            </Text>
            <Schedule />
        </Flex>
    </Page>
);

export default HomePage;
