import React from 'react';
import { Box } from 'rebass';
import PropTypes from 'prop-types';

const Backdrop = props => {
    const { onClick } = props;

    return (
        <Box
            sx={{
                position: 'fixed',
                background: 'rgb(0, 0, 0, 0.5)',
                width: '100%',
                height: '100%',
                zIndex: 10,
            }}
            onClick={onClick}
        />
    );
};

Backdrop.propTypes = {
    onClick: PropTypes.func,
};

Backdrop.defaultProps = {
    onClick: null,
};

export default Backdrop;
