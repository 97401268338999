import React from 'react';
import { Label, Select } from '@rebass/forms';
import PropTypes from 'prop-types';
import colorPalettes from '../../theme/colorPalettes';
import { defaultColorPalette } from '../../theme';

const PalettePicker = props => {
    const { onPaletteChange } = props;

    const colorTypes = ['Default', ...Object.keys(colorPalettes)];

    return (
        <>
            <Label bold htmlFor="theme-control">
                Color Palette
            </Label>
            <Select
                sx={{
                    color: 'text',
                    bg: 'background',
                    fontFamily: 'body',
                    fontWeight: 'body',
                    lineHeight: 'body',
                }}
                id="theme-control"
                name="theme-control"
                width="140px"
                defaultValue={defaultColorPalette}
                onChange={event => onPaletteChange(event.target.value)}
            >
                {colorTypes.map(t => (
                    <option key={t}>{t}</option>
                ))}
            </Select>
        </>
    );
};

PalettePicker.propTypes = {
    onPaletteChange: PropTypes.func.isRequired,
};

export default PalettePicker;
