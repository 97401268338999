import React from 'react';
import { Flex, Text } from 'rebass';
import Page from './ui/Page';
import Image from './ui/Image';
import TextSection from './ui/TextSection';

import header from '../assets/travel/header.jpg';
import drivers from '../assets/travel/drivers.jpg';
import riders from '../assets/travel/riders.jpg';
import flyers from '../assets/travel/flyers.jpg';
import unicornTravel from '../assets/travel/unicorn-travel.jpg';
import unicornCar from '../assets/travel/unicorn-car.png';
import unicornTrain from '../assets/travel/unicorn-train.jpg';
import flyingUnicorn from '../assets/travel/flying-unicorn.jpg';

const tripAdvisorUrl =
    'https://www.tripadvisor.com/Trips/103717623/Wedding%20Weekend%20-%20Connecticut?m=19905';

const TravelPage = () => (
    <Page headingText="Kristen's Travel Tips">
        <Flex flexDirection="column" alignItems="center" justifyContent="space-around">
            <Image src={header} unicornSrc={unicornTravel} width="70%" m={[2, 3, 4]} />
            <Text fontSize={[3, 4, 5]} margin="30px 0">
                Whether you’re driving, flying, riding or hitching, we have all the
                details you need to plan your trip for our wedding. Kristen considers
                herself a bit of a travel planning whiz so choose your own adventure.
            </Text>

            <TextSection heading="Kristen's Trip Advisor">
                For the guests visiting from out of state, Kristen has created a trip
                advisor with some fun things to do in the great state of Connecticut!
            </TextSection>
            <a href={tripAdvisorUrl} target="_blank" rel="noopener noreferrer">
                Tripadvisor: Wedding Weekend CT
            </a>

            <TextSection heading="Drivers" imageSrc={drivers} unicornSrc={unicornCar}>
                The wedding will take place at Holiday Hill Day Camp and Recreation Center
                in Mansfield Center CT. Please do not drive to the Holiday Hill in Putnam
                CT; we will not be there. From the North you’ll take I-84 into Connecticut
                and take Exit 72. The venue is about 20 min off the highway. From the
                South we recommend you take the Merritt Parkway to I-91 up to Hartford.
                You’ll then take I-84/I-384 to US 44 and the venue is 20 min away from
                there.
            </TextSection>

            <TextSection heading="Riders" imageSrc={riders} unicornSrc={unicornTrain}>
                Don’t. The closest bus goes to Union Station in Hartford and then you
                could maybe take the 913 bus to Storrs but then you’re still 4 miles from
                the venue. Connecticut has yet to discover subway technology.
            </TextSection>

            <TextSection heading="Flyers" imageSrc={flyers} unicornSrc={flyingUnicorn}>
                You have 1 closer option and 2 further options. The closest is Hartford
                Bradley Airport (BDL). There are direct flights from Cincinnati,
                Cleveland, and Detroit through Delta. The venue is about 45 min from the
                airport so you will probably need to rent a car. Nearly all of the major
                rental car companies operate out of Bradley. Further out, you could travel
                into Boston Logan (BOS) or TF Green in Providence RI (PVD). Providence is
                about 1 hr and 15 and Boston is about 1 hr and 40. Boston will not be fun
                to drive out of though so I would recommend Providence if Hartford will
                not work. There are direct flights to Providence from Detroit and
                Cincinnati.
            </TextSection>
        </Flex>
    </Page>
);

export default TravelPage;
