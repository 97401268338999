import React from 'react';
import { Flex, Heading } from 'rebass';
import Page from '../ui/Page';
import Pika from './Pika';

const PikaPage = () => (
    <Page>
        <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
            height="100%"
        >
            <Heading variant="display">Pikachu is Back!</Heading>
            <Pika />
        </Flex>
    </Page>
);

export default PikaPage;
