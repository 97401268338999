import React from 'react';
import { Flex, Text } from 'rebass';
import Page from './ui/Page';
import Image from './ui/Image';
import TextSection from './ui/TextSection';
import house from '../assets/friday/house.jpg';
import johnDiane from '../assets/friday/john-diane.jpg';
import pitmaster from '../assets/friday/pitmaster.jpg';
import townHall from '../assets/friday/town-hall.png';
import unicornParty from '../assets/friday/unicorn-party.jpg';
import unicornBbq from '../assets/friday/unicorn-bbq.jpg';
import unicornParking from '../assets/friday/unicorn-parking.jpg';
import newParking from '../assets/friday/new-parking.png';
import johnDianeUnicorn from '../assets/friday/john-diane-unicorn.jpg';

const location = '149 Main st. Hampton CT';

const FridayPage = () => (
    <Page headingText="Friday Shindig">
        <Flex flexDirection="column" alignItems="center" justifyContent="space-around">
            <Text fontSize={[3, 4, 5]} m={2}>
                6/11, 5:30 - 10:00
            </Text>
            <Image src={house} unicornSrc={unicornParty} width="70%" m={[2, 3, 4]} />
            <Text fontSize={[3, 4, 5]} margin="30px 0">
                You’re invited to our Friday &quot;rehearsal&quot; party! We didn’t feel
                the need to create an exclusive rehearsal dinner guest list, so we invited
                the whole dang wedding!
                <ul>
                    <li>
                        Attendance is optional but let us know if you intend on coming in
                        the RSVP
                    </li>
                </ul>
                <ul>
                    <li>Dancing shoes can remain at the hotel for this event</li>
                </ul>
            </Text>

            <TextSection
                heading="The Hosts"
                imageSrc={johnDiane}
                unicornSrc={johnDianeUnicorn}
            >
                Meet these party animals: John, Diane, and Jack Tillinghast (parents of
                the groom). They will be hosting the shindig at {location}. They can
                provide food, drink, and fun people to hang out with.
            </TextSection>

            <TextSection
                heading="The Pitmaster"
                imageSrc={pitmaster}
                unicornSrc={unicornBbq}
                maxImgWidth="450px"
            >
                We’ll be having a pig roast, and we spared no expense hiring the best. One
                of the more infamous members of the underground New Hampshire seacoast
                pitmaster scene, Ben’s excited to make landfall in Connecticut BBQ
                culture.
            </TextSection>
            <TextSection m={4}>
                &quot;Connecticut? They wont be hard to impress.&quot; -Benny Smokes
            </TextSection>
            <TextSection
                heading="The Parking"
                imageSrc={[townHall, newParking]}
                unicornSrc={unicornParking}
            >
                Don&apos;t park at the town hall, park at the neighbor&apos;s across the
                street
            </TextSection>
        </Flex>
    </Page>
);

export default FridayPage;
