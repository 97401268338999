import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from './components/AppLayout';

const App = () => (
    <BrowserRouter>
        <AppLayout />
    </BrowserRouter>
);

export default App;
