import React from 'react';
import Page from './ui/Page';
import GoogleForm from './ui/GoogleForm';

const rsvpUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLSfChz0tyrBQeDVIsi1xJryYstT6BDBfrv-j3R5GRnRbuqCaJQ/viewform';

const RsvpPage = () => (
    <Page>
        <GoogleForm url={rsvpUrl} />
    </Page>
);

export default RsvpPage;
