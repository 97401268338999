import React, { useContext } from 'react';
import { Flex, Image as RebassImage } from 'rebass';
import PropTypes from 'prop-types';
import { ModeContext } from '../../theme';

export const imgSrcType = PropTypes.oneOfType([PropTypes.string, PropTypes.array]);

const getRebassImg = (fwdProps, src) => <RebassImage key={src} {...fwdProps} src={src} />;

const Image = props => {
    const { src, unicornSrc, ...fwdProps } = props;
    const mode = useContext(ModeContext);

    const modeSrc = unicornSrc && mode === 'unicorn' ? unicornSrc : src;
    if (!Array.isArray(modeSrc)) {
        return getRebassImg(fwdProps, modeSrc);
    }

    return (
        <Flex
            flexDirection={['column', 'column', 'row']}
            width="100%"
            alignItems="center"
        >
            {modeSrc.map(srcStr => getRebassImg(fwdProps, srcStr))}
        </Flex>
    );
};

Image.propTypes = {
    src: imgSrcType.isRequired,
    unicornSrc: imgSrcType,
};

Image.defaultProps = {
    unicornSrc: null,
};

export default Image;
