import React from 'react';
import { Text, Flex } from 'rebass';
import Page from './ui/Page';
import Emoji, { emojis } from './ui/Emoji';

const RsvpPage = () => (
    <Page headingText="Gift Registries">
        <Text fontSize={[2, 3, 4]} fontWeight={3} m={3}>
            You don&apos;t need to buy us anything, but ya know... tradition! Here&apos;s
            some ideas!
        </Text>
        <Flex fontSize={4} flexDirection="column" alignItems="center">
            <a
                href="https://www.amazon.com/wedding/share/kristenbreton"
                target="_blank"
                rel="noopener noreferrer"
            >
                Amazon Registry
            </a>
            <a
                href="https://www.zola.com/registry/bretonandkristen"
                target="_blank"
                rel="noopener noreferrer"
            >
                Zola Registry
            </a>
        </Flex>
        <Text fontSize={[3, 4, 5]} m={3}>
            ( we also enjoy money <Emoji emoji={emojis.WINK} /> )
        </Text>
    </Page>
);

export default RsvpPage;
