import React from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import PalettePicker from './PalettePicker';

const ThemeControls = props => {
    const { onColorPaletteChange } = props;

    return (
        <Flex flexDirection="column" justifyContent="space-between">
            <Box flexDirection="row" alignItems="center" m={2}>
                <PalettePicker onPaletteChange={onColorPaletteChange} />
            </Box>
        </Flex>
    );
};

ThemeControls.propTypes = {
    onColorPaletteChange: PropTypes.func.isRequired,
};

export default ThemeControls;
