import React from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import AppLink from '../../ui/AppLink';
import Image from '../../ui/Image';
import UnicornModeToggle from '../../ui/UnicornModeToggle';
import Backdrop from './Backdrop';
import { routes } from '../PageRoutes';
import drawerFlowers from '../../../assets/drawer-flowers.jpg';
import drawerUnicorn from '../../../assets/drawer-unicorn.png';

const SideDrawer = props => {
    const { open, onClose, isUnicornMode, onUnicornChange } = props;

    return (
        <>
            <Box
                paddingTop="34px"
                backgroundColor="header"
                variant="sidebar"
                width="240px"
                maxWidth="70%"
                height="100vh"
                sx={{
                    position: 'fixed',
                    zIndex: 20,
                    left: 0,
                    top: 0,
                    transition: 'transform 0.3s ease-out',
                    transform: open ? 'translateX(0)' : 'translateX(-100%)',
                }}
            >
                <Image
                    src={drawerFlowers}
                    unicornSrc={drawerUnicorn}
                    opacity="50%"
                    sx={{
                        position: 'absolute',
                    }}
                />
                <Flex flexDirection="column" alignItems="center" mt="10px">
                    {routes.map(
                        ({ linkText, path }) =>
                            linkText && (
                                <AppLink
                                    key={path}
                                    to={path}
                                    variant="sidebarNav"
                                    onClick={onClose}
                                    sx={{ zIndex: 30 }}
                                >
                                    {linkText}
                                </AppLink>
                            )
                    )}
                    <Box mt={3}>
                        <UnicornModeToggle
                            defaultChecked={isUnicornMode}
                            onChange={onUnicornChange}
                        />
                    </Box>
                </Flex>
            </Box>
            {open && <Backdrop onClick={onClose} />}
        </>
    );
};

SideDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    isUnicornMode: PropTypes.bool.isRequired,
    onUnicornChange: PropTypes.func,
};

SideDrawer.defaultProps = {
    onClose: null,
    onUnicornChange: null,
};

export default SideDrawer;
