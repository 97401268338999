import React from 'react';
import { Button } from 'rebass';
import PropTypes from 'prop-types';
import Image from '../ui/Image';
import hamburger from '../../assets/hamburger.svg';

const DrawerToggle = props => {
    const { onClick } = props;

    return (
        <Button onClick={onClick} bg="transparent" minWidth="50px">
            <Image src={hamburger} cursor="pointer" />
        </Button>
    );
};

DrawerToggle.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default DrawerToggle;
