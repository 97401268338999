import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import PropTypes from 'prop-types';
import Image, { imgSrcType } from './Image';

const TextSection = props => {
    const {
        heading,
        children,
        imageSrc,
        unicornSrc,
        imgWidth,
        maxImgWidth,
        maxImgHeight,
        ...fwdProps
    } = props;

    return (
        <Flex flexDirection="column" m={1} alignItems="center" {...fwdProps}>
            {heading && (
                <Heading
                    variant="display"
                    color="heading"
                    fontSize={[4, 5, 6]}
                    textAlign="center"
                    m={2}
                >
                    {heading}
                </Heading>
            )}
            {imageSrc && (
                <Image
                    src={imageSrc}
                    unicornSrc={unicornSrc}
                    width={imgWidth}
                    m={[2, 3, 4]}
                    maxWidth={maxImgWidth}
                    maxHeight={maxImgHeight}
                />
            )}
            <Text fontSize={[2, 3, 4]}> {children}</Text>
        </Flex>
    );
};

TextSection.propTypes = {
    heading: PropTypes.string,
    children: PropTypes.node.isRequired,
    imageSrc: imgSrcType,
    unicornSrc: imgSrcType,
    imgWidth: PropTypes.string,
    maxImgWidth: PropTypes.string,
    maxImgHeight: PropTypes.string,
};

TextSection.defaultProps = {
    heading: null,
    imageSrc: null,
    unicornSrc: null,
    imgWidth: '70%',
    maxImgWidth: undefined,
    maxImgHeight: undefined,
};

export default TextSection;
