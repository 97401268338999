import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HomePage from '../HomePage';
import ThemeDemoPage from '../ThemeDemoPage';
import TravelPage from '../TravelPage';
import LodgingPage from '../LodgingPage';
import FridayPage from '../FridayPage';
import RsvpPage from '../RsvpPage';
import DressPage from '../DressPage';
import PikaPage from '../PikaPage';
import CovidPage from '../CovidPage';
import GiftsPage from '../GiftsPage';

export const routes = [
    {
        linkText: 'Home',
        path: '/',
        component: HomePage,
    },
    {
        linkText: 'RSVP',
        path: '/rsvp',
        component: RsvpPage,
    },
    {
        linkText: 'Friday',
        path: '/friday',
        component: FridayPage,
    },
    {
        linkText: 'Travel',
        path: '/travel',
        component: TravelPage,
    },
    {
        linkText: 'Gifts',
        path: '/gifts',
        component: GiftsPage,
    },
    {
        linkText: 'Dress',
        path: '/dress',
        component: DressPage,
    },
    {
        linkText: 'Lodging',
        path: '/lodging',
        component: LodgingPage,
    },
    {
        linkText: null,
        path: '/pika',
        component: PikaPage,
    },

    {
        linkText: 'COVID',
        path: '/covid',
        component: CovidPage,
    },
    {
        linkText: null,
        path: '/demo',
        component: ThemeDemoPage,
    },
];

const PageRoutes = () => (
    <Switch>
        {routes.map(({ path, component }) => (
            <Route key={path} path={path} exact component={component} />
        ))}
        <Redirect to="/" />
    </Switch>
);

export default PageRoutes;
