import React, { useEffect } from 'react';
import { Box, Flex, Heading } from 'rebass';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUnicornMode } from '../../hooks';
import unicornImgTile from '../../assets/unicorn-tile.png';

const UnicornBackround = () => (
    <Box
        sx={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${unicornImgTile})`,
            backgroundRepeat: 'repeat',
            backgroundSize: ['100px', '150px', undefined],
            opacity: '17%',
            zIndex: 2,
        }}
    />
);

const Page = ({ headingText, children, history }) => {
    const isUnicornMode = useUnicornMode();

    useEffect(
        () =>
            history.listen(() => {
                window.scrollTo(0, 0);
            }),
        [history]
    );

    return (
        <>
            {isUnicornMode && <UnicornBackround />}
            <Box
                sx={{
                    p: 4,
                    color: 'text',
                    bg: isUnicornMode ? 'transparant' : 'background',
                    fontFamily: 'body',
                    fontWeight: 'body',
                    lineHeight: 'body',
                    minHeight: '100vh',
                    zIndex: 5,
                }}
            >
                <Box
                    maxWidth="1000px"
                    margin="auto"
                    marginTop={['40px', '40px', '260px']}
                >
                    {headingText ? (
                        <Flex flexDirection="column" alignItems="center">
                            <Heading variant="display" color="heading">
                                {headingText}
                            </Heading>
                            {children}
                        </Flex>
                    ) : (
                        children
                    )}
                </Box>
            </Box>
        </>
    );
};

Page.propTypes = {
    headingText: PropTypes.string,
    children: PropTypes.node.isRequired,
    history: PropTypes.shape({
        listen: PropTypes.func.isRequired,
    }).isRequired,
};

Page.defaultProps = {
    headingText: null,
};

export default withRouter(Page);
