import React from 'react';
import { Flex, Text } from 'rebass';
import Emoji, { emojis } from '../ui/Emoji';
import AppLink from '../ui/AppLink';

const Covid = () => (
    <Flex flexDirection="column" alignItems="center" p={3} paddingBottom="20px">
        <Text fontSize={['29px', 6, 7]} color="#f71111">
            <Emoji emoji={emojis.MASK} /> COVID UPDATE <Emoji emoji={emojis.SICK} />
        </Text>
        <Text fontSize={[3, 4]}>
            As of March, the CT Governor has allowed weddings of up to 200 guests
            outdoors. We are lucky to have such a great outdoor venue and are moving
            forward with the wedding! <Emoji emoji={emojis.JOY} /> If COVID conditions
            change, we&apos;ll update everyone accordingly! We&apos;ve also added a page
            for COVID safety information
            <AppLink to="covid" variant="primary" m={1}>
                here
            </AppLink>
            .
        </Text>
    </Flex>
);

export default Covid;
