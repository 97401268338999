import React from 'react';
import { Flex, Text } from 'rebass';
import Page from './ui/Page';
import Image from './ui/Image';
import TextSection from './ui/TextSection';
import { useUnicornMode } from '../hooks';
import brideGroomMasks from '../assets/covid/bride-groom-masks.png';
import kristenVcaccine from '../assets/covid/kristen-vaccine.jpg';
import welcomeToCt from '../assets/covid/welcome-to-ct.jpeg';
import socialDistancing from '../assets/covid/social-distancing.jpeg';
import unicornBarbedWire from '../assets/covid/unicorn-barbed-wire.jpeg';
import unicornMask from '../assets/covid/unicorn-mask.png';
import unicornNurse from '../assets/covid/unicorn-nurse.png';
import unicornDistance from '../assets/covid/unicorn-distance.jpeg';
import tinaKelseyMasks from '../assets/covid/tina-kelsey-masks.jpg';

const CovidPage = () => {
    const isUnicornMode = useUnicornMode();

    return (
        <Page headingText="COVID Safe">
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
            >
                <Image
                    maxWidth={['300px', '500px']}
                    m={[2, 3, 4]}
                    src={brideGroomMasks}
                />
                <Text fontSize={[3, 4, 5]} margin="30px 0">
                    Alright, so not to mince words, pandemics freaking suck. However,
                    we&apos;re in the home stretch and we believe we can have a safe and
                    great wedding! We want to provide some details on the expectations and
                    differences about the day.
                </Text>

                <TextSection
                    heading="Masks"
                    imageSrc={tinaKelseyMasks}
                    unicornSrc={unicornMask}
                    maxImgWidth="450px"
                >
                    Yes, we can still expect to need masks in June. Please plan
                    accordingly. We ARE looking for some masks with straw holes or flaps
                    for your beverage convenience. And like many restaurants, you
                    won&apos;t be expected to wear them at your table. We&apos;ll also
                    have hand sanitizer!
                </TextSection>

                <TextSection
                    heading="Social Distancing"
                    imageSrc={socialDistancing}
                    unicornSrc={unicornDistance}
                    maxImgWidth="450px"
                >
                    Our venue is large, quite large. We&apos;ll have the ability to
                    socially distance the ceremony chairs as needed and the tables under
                    the tent. Should the weather be nice, we&apos;re even looking at
                    moving the dance floor next to the tent on the basketball courts for
                    maximum space and socially distanced shenanigans.
                </TextSection>
                <TextSection
                    heading="Vaccinations / Testing"
                    imageSrc={kristenVcaccine}
                    unicornSrc={unicornNurse}
                    maxImgWidth={isUnicornMode ? '400px' : '500px'}
                >
                    Nobody wants a super spreader! We hope all of our guests have the
                    opportunity to receive the vaccine before mid-June. We know it may not
                    be possible for everyone, but certainly encourage everyone to do their
                    part to end the pandemic. If you can&apos;t get vaccinated, we would
                    appreciate you being mindful of social distancing and masks in the 10
                    days leading up to the wedding weekend or taking a COVID test about 3
                    days before the wedding. Please help us protect everyone!
                </TextSection>
                <TextSection
                    heading="Travel Restrictions"
                    imageSrc={welcomeToCt}
                    unicornSrc={unicornBarbedWire}
                    maxImgWidth="500px"
                    mb="80px"
                >
                    At the end of March, CT will implement changes to its current travel
                    restrictions. We expect that the state will recommend but not require
                    a quarantine period on arrival in the state. The current status can be
                    found at{' '}
                    <a href="https://portal.ct.gov/Coronavirus/Covid-19-Knowledge-Base/Travel">
                        CT Travel Advisory
                    </a>{' '}
                    (we&apos;ll also be in touch if this is an issue closer to the
                    wedding).
                    <br />
                    <br />
                    Travelers from other states will be expected to complete a travel form
                    on arrival, found{' '}
                    <a href="https://appengine.egov.com/apps/ct/DPH/Connecticut-Travel-Health-Form">
                        here
                    </a>
                    .
                </TextSection>

                <div className="beach-casual-video">
                    <iframe
                        type="text/html"
                        src="https://www.youtube.com/embed/7b9hV0oU_-c?autoplay=1&mute=0"
                        width="420"
                        height="315"
                        frameBorder="0"
                        title="hey-19-steely-dan"
                        allow="autoplay"
                    />
                </div>
            </Flex>
        </Page>
    );
};

export default CovidPage;
