import React from 'react';
import { Box, Text } from 'rebass';
import TextSection from '../ui/TextSection';

const schedule = [
    {
        title: 'Transport',
        time: '3:30-4:45 pm',
        text:
            'Shuttles start running from the hotels. Just wait outside your hotel and look for the Holiday Hill marked mini-vans/passenger van.',
    },
    {
        title: 'Family Photos With the Couple',
        time: '4:15 pm',
        text:
            'Family members are asked to arrive before the ceremony for family photos. Please meet by white barn.',
    },
    {
        title: 'Ceremony',
        time: '4:45 pm',
        text:
            'Outside on the lawn. We recommend that you wear wide-heeled shoes or flats since we’ll be on the grass.',
        bullets: [
            'Inclement weather: Should Mother Nature grace us with wind and rain, we’ll have the ceremony in the big white barn (look for the weathervane on top).',
        ],
    },
    {
        title: 'Drinks on the Links',
        time: '5:00 pm',
        text:
            'Grab a beverage, an appetizer and enjoy the grounds of the day camp. Check out the mini-golf, horseshoes, bocce or pickleball.',
        bullets: [
            'For the kids: we’ll have kites on the lawn and there’s a playground right next to the reception tent so you‘ll be able to keep an eye on them while enjoying the party. Because the venue is a day camp, the entire property is pretty kid friendly.',
        ],
    },
    {
        title: 'Dinner',
        time: '6:00 pm',
        text:
            'Join us in the big white tent (you can’t miss it) for a delicious meal from some of the best food trucks CT has to offer.',
    },
    {
        title: 'Dancing and general merriment',
        time: '7:00-10:00 pm',
        text: 'Cut a rug, get your drink on, and party!',
    },
    {
        title: 'Transport',
        time: '9:00-11:00 pm',
        text: 'Shuttles starting making runs back to the hotels.',
    },
    {
        title: 'Bonfire and After Party',
        time: '10:00 pm',
        text: 'Stick around for a little longer and have a night cap or two.',
    },
];

const Schedule = () => (
    <Box marginTop="20px" textAlign="left" font={[2, 3, 3]}>
        <TextSection heading="Schedule">
            {schedule.map(({ title, time, text, bullets }) => (
                <Box key={time} m={3}>
                    <Text fontWeight={600}>
                        {time} - {title}
                    </Text>
                    <Text>{text}</Text>
                    {bullets &&
                        bullets.map(bullet => (
                            <Text key={bullet} ml="20px">
                                * {bullet}
                            </Text>
                        ))}
                </Box>
            ))}
        </TextSection>
    </Box>
);

export default Schedule;
