import rebassTheme from '@rebass/preset';

export default (theme, colorPalette, colorKey) => {
    const buttonOverrides = {
        cursor: 'pointer',
    };

    const combined = {
        ...rebassTheme,
        ...theme,
        colors: {
            ...theme.colors,
            ...colorPalette,
        },
    };

    // Override colors

    // Spread button overrides into each theme.buttons keys
    const buttons = Object.entries(combined.buttons).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: {
                ...value,
                ...buttonOverrides,
            },
        }),
        {}
    );

    const fontConfigs = {
        unicorn: {
            heading: 'Great Vibes, cursive',
            body: 'Great Vibes, cursive',
        },
        default: {
            heading: 'Dancing Script',
            body: 'Bentham, serif',
        },
    };

    const fonts = {
        ...theme.fonts,
        ...(fontConfigs[colorKey] || fontConfigs.default),
    };

    const navVariant = {
        ...(theme.variants || {}).nav,
        fontSize: 3,
        fontWeight: 700,
        fontFamily: 'Bentham',
    };

    const variants = {
        ...theme.variants,
        sidebar: {
            bg: '#f6f6f6',
        },
        nav: navVariant,
        sidebarNav: {
            ...navVariant,
            color: 'text',
            primary: 'heading',
            backgroundColor: 'background',
            borderRadius: '12px',
            padding: '8px',
        },
        link: {
            ...theme.variants.link,
            textDecoration: 'none',
        },
    };

    const text = {
        ...theme.text,
        heading: {
            ...theme.text.heading,
            color: 'headerText',
        },
    };

    const breakpoints = ['640px', '832px', '1024px'];

    const appTheme = {
        ...combined,
        buttons,
        fonts,
        variants,
        text,
        breakpoints,
    };

    // console.log(appTheme);

    return appTheme;
};
