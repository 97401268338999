import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Link } from 'rebass';
import Image from './ui/Image';
import Page from './ui/Page';
import TextSection from './ui/TextSection';
import inflatable from '../assets/lodging/inflatable.jpg';
import innOnStores from '../assets/lodging/inn-on-stores.png';
import theGraduate from '../assets/lodging/the-graduate.jpg';
import stoneArches from '../assets/lodging/stone-arches.png';
import fitchHouse from '../assets/lodging/fitch-house.jpg';
import springHillInn from '../assets/lodging/spring-hill-inn.png';
import unicornBed from '../assets/lodging/unicorn-bed.jpg';

const LodgingLocal = props => {
    const { title, website, textParts, imgSrc, imgHeight } = props;

    return (
        <Flex flexDirection="column" m={3}>
            <Text fontSize={[3, 4, 5]}>
                <Link href={website} target="_blank">
                    {title}
                    <Flex
                        height={[undefined, undefined, imgHeight]}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image width="400px" src={imgSrc} m={3} />
                    </Flex>
                </Link>
            </Text>
            <Text fontSize={[2, 3, 3]} textAlign="center">
                {textParts.map(textPart => (
                    <Fragment key={textPart}>
                        {textPart}
                        <br />
                    </Fragment>
                ))}
            </Text>
        </Flex>
    );
};
LodgingLocal.propTypes = {
    imgSrc: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    textParts: PropTypes.arrayOf(PropTypes.node).isRequired,
    imgHeight: PropTypes.string.isRequired,
};

const LodgingPage = () => (
    <Page headingText="Places to Stay">
        <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
            height="100%"
            textAlign="center"
        >
            <Image src={inflatable} unicornSrc={unicornBed} width="600px" m={4} />
            <Text fontSize={[3, 4, 5]} margin="30px 0">
                Don&apos;t worry about finding a place to stay, we&apos;ve got you
                covered!
            </Text>

            <TextSection heading="Hotel Blocks">
                We&apos;ve booked two large hotel blocks for both Friday and Saturday
                located within a few miles of the wedding. They&apos;ll be shuttle service
                running on Saturday so don&apos;t worry about driving that day.
            </TextSection>
            <Flex flexDirection={['column', 'column', 'row']}>
                <LodgingLocal
                    title="The Inn on Storrs"
                    imgSrc={innOnStores}
                    website="http://www.theinnonstorrs.com"
                    imgHeight="218px"
                    textParts={[
                        'The first hotel block is at The Inn on Storrs.',
                        '123 Storrs Rd. Mansfield Center, CT 06250',
                        <br />,
                        "This hotel is located 5 minutes from the venue. For those looking to do a bit of shopping, it's within walking distance of the East Brook Mall! ($110 per night)",
                        <br />,
                        <span>
                            To book a room, call{' '}
                            <a href="tel:860-423-8451">860-423-8451</a> and reserve under
                            &quot;Binau Tillinghast&quot; room block.
                        </span>,
                    ]}
                />

                <LodgingLocal
                    title="The Graduate"
                    imgSrc={theGraduate}
                    website="https://www.graduatehotels.com/storrs"
                    imgHeight="218px"
                    textParts={[
                        'The second hotel block is at The Graduate.',
                        '855 Bolton Rd. Storrs, CT 06268',
                        <br />,
                        "It's located about 8 minutes from the venue. Husky fan? This is in the same town as UConn! ($151 - $159 per night)",
                        <br />,
                        <span>
                            To book a room, click{' '}
                            <a href="https://be.synxis.com/?adult=1&arrive=2021-06-11&chain=21643&child=0&config=sales&currency=USD&depart=2021-06-13&group=061121BTWD&hotel=8679&level=hotel&locale=en-US&rooms=1">
                                Graduate Hotel Block
                            </a>{' '}
                            or call <a href="tel:860-427-7888">860-427-7888</a> and
                            reserve under &quot;Binau Tillinghast&quot; room block.
                        </span>,
                    ]}
                />
            </Flex>

            <TextSection heading="Bed & Breakfast?" margin="40px 0">
                For the fancier folk, Mansfield CT has plenty of BnBs to chose from.
            </TextSection>
            <Flex flexDirection={['column', 'column', 'row']}>
                <LodgingLocal
                    title="Fitch House"
                    imgSrc={fitchHouse}
                    website="http://www.fitchhouse.com"
                    imgHeight="324px"
                    textParts={['563 Storrs Rd. Mansfield Center, CT 06250']}
                />
                <LodgingLocal
                    title="Stone Arches"
                    imgSrc={stoneArches}
                    website="https://www.stonearchesbnb.com"
                    imgHeight="324px"
                    textParts={['614 Storrs Rd. Mansfield Center, CT 06250']}
                />
                <LodgingLocal
                    title="Spring Hill Inn"
                    imgSrc={springHillInn}
                    website="https://www.springhillinnct.com"
                    imgHeight="324px"
                    textParts={['957 Storrs Rd. Storrs, CT 06268']}
                />
            </Flex>
        </Flex>
    </Page>
);

export default LodgingPage;
