import React from 'react';
import PropTypes from 'prop-types';

export const emojis = {
    INQUISITIVE: 'inquisitive',
    CHATTERING: 'chattering',
    SICK: 'sick',
    MASK: 'mask',
    SCREAMING: 'screaming',
    JOY: 'joy',
    WINK: 'wink',
};

const Emoji = ({ emoji }) => {
    let emojiChar;

    switch (emoji) {
        case emojis.INQUISITIVE:
            emojiChar = <>🤔</>;
            break;
        case emojis.CHATTERING:
            emojiChar = <>😬</>;
            break;
        case emojis.SICK:
            emojiChar = <>🤒</>;
            break;
        case emojis.MASK:
            emojiChar = <>😷</>;
            break;
        case emojis.SCREAMING:
            emojiChar = <>😱</>;
            break;
        case emojis.JOY:
            emojiChar = <>😄</>;
            break;
        case emojis.WINK:
            emojiChar = <>😉</>;
            break;
        default:
            throw new Error(`Invalid Emoji: ${emoji}`);
    }

    return (
        <span role="img" aria-label={emojis[emoji]}>
            {emojiChar}
        </span>
    );
};

Emoji.propTypes = {
    emoji: PropTypes.oneOf(Object.values(emojis)).isRequired,
};

export default Emoji;
