import React from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link as RebassLink } from 'rebass';

const AppLink = props => {
    const { to, activeColor, variant } = props;
    const history = useHistory();
    const isActive = history.location.pathname === to;

    return (
        <RebassLink
            sx={{
                fontWeight: 'bold',
            }}
            m={['8px', 3, 3]}
            variant={variant}
            fontWeight="400"
            color={isActive && activeColor}
            {...props}
            as={RouterLink}
        />
    );
};

AppLink.propTypes = {
    to: PropTypes.string.isRequired,
    activeColor: PropTypes.string,
    variant: PropTypes.string,
};

AppLink.defaultProps = {
    variant: 'nav',
    activeColor: 'primary',
};

export default AppLink;
