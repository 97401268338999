import React from 'react';
import { Flex, Text } from 'rebass';
import Page from '../ui/Page';
import Image from '../ui/Image';
import Emoji, { emojis } from '../ui/Emoji';
import './DressPage.css';

import ryan from '../../assets/dress/ryan.jpg';
import ryanCorn from '../../assets/dress/ryan-corn.jpg';

const DressPage = () => (
    <Page headingText='Dress Code: "Beach Formal"'>
        <Flex flexDirection="column" alignItems="center" justifyContent="space-around">
            <Image
                src={ryan}
                unicornSrc={ryanCorn}
                m={3}
                sx={{
                    borderRadius: '90px',
                }}
            />
            <Text fontSize={[3, 4, 5]} m={3}>
                Ryan Gosling IS beach formal.
            </Text>
            <Text fontSize={[3, 4, 5]} m={4}>
                for those who require more explaination...
            </Text>
            <div className="beach-casual-video">
                <iframe
                    type="text/html"
                    src="https://www.youtube.com/embed/WRoCiVVnN40"
                    width="420"
                    height="315"
                    frameBorder="0"
                    title="beach-casual-video"
                />
            </div>
            <Text fontSize={[4, 5, 6]} margin="100px 0 0 0" textAlign="center">
                Oh no! <Emoji emoji={emojis.CHATTERING} />
            </Text>
            <Text fontSize={[3, 4, 5]} textAlign="center">
                It&apos;s the night before the wedding and I couldn&apos;t pull together a
                beach formal look together! Should I still attend the wedding?{' '}
                <Emoji emoji={emojis.INQUISITIVE} />
                <br />
                YES! We&apos;d still love to have you. Don&apos;t worry, dress codes are
                stupid.
            </Text>
        </Flex>
    </Page>
);

export default DressPage;
