import React from 'react';
import { Box } from 'rebass';
import { routes } from './PageRoutes';
import AppLink from '../ui/AppLink';

const NavBar = () => (
    <Box>
        {routes.map(
            ({ linkText, path }) =>
                linkText && (
                    <AppLink key={path} to={path} fontSize={[5]}>
                        {linkText}
                    </AppLink>
                )
        )}
    </Box>
);

export default NavBar;
