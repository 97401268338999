import React from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import NavBar from '../NavBar';
import Image from '../../ui/Image';
import DrawerToggle from '../DrawerToggle';
import headerImg from '../../../assets/header.jpg';
import unicornHeaderImg from '../../../assets/unicorn-header.png';
import './Header.css';

const Header = props => {
    const { onDrawerToggle } = props;

    return (
        <Flex
            bg="header"
            sp
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            sx={{
                position: 'fixed',
                top: 0,
                zIndex: 15,
            }}
        >
            <div className="header-drawer-toggle">
                <Box flexGrow="0">
                    <DrawerToggle onClick={onDrawerToggle} />
                </Box>
            </div>
            <Flex flexDirection="column" alignItems="center" flexGrow="1">
                <Image
                    src={headerImg}
                    unicornSrc={unicornHeaderImg}
                    height={['64px', '64px', '180px']}
                    width="100%"
                    sx={{
                        objectFit: 'cover',
                    }}
                />
                <div className="header-nav-bar">
                    <NavBar />
                </div>
            </Flex>
        </Flex>
    );
};

Header.propTypes = {
    onDrawerToggle: PropTypes.func,
};

Header.defaultProps = {
    onDrawerToggle: null,
};

export default Header;
