import photo1 from '../../../assets/photo-bucket/photo01.jpg';
import photo2 from '../../../assets/photo-bucket/photo02.jpg';
import photo3 from '../../../assets/photo-bucket/photo03.jpg';
import photo4 from '../../../assets/photo-bucket/photo04.jpg';
import photo5 from '../../../assets/photo-bucket/photo05.jpg';
import photo6 from '../../../assets/photo-bucket/photo06.jpg';
import photo7 from '../../../assets/photo-bucket/photo07.jpg';
import photo8 from '../../../assets/photo-bucket/photo08.jpg';
import photo9 from '../../../assets/photo-bucket/photo09.jpg';
import photo10 from '../../../assets/photo-bucket/photo10.jpg';
import photo11 from '../../../assets/photo-bucket/photo11.jpg';
import photo12 from '../../../assets/photo-bucket/photo12.jpg';
import photo13 from '../../../assets/photo-bucket/photo13.jpg';
import photo14 from '../../../assets/photo-bucket/photo14.jpg';
import photo15 from '../../../assets/photo-bucket/photo15.jpg';
// import photo16 from '../../../assets/photo-bucket/photo16.jpg';
import photo17 from '../../../assets/photo-bucket/photo17.jpg';
import photo18 from '../../../assets/photo-bucket/photo18.jpg';
import photo19 from '../../../assets/photo-bucket/photo19.jpg';
import photo20 from '../../../assets/photo-bucket/photo20.jpg';

export default [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    // photo16,
    photo17,
    photo18,
    photo19,
    photo20,
];
