import React, { useState } from 'react';
import { Box, Text } from 'rebass';
import UIfx from 'uifx';
import Image from '../../ui/Image';
import construction from '../../../assets/pika/construction.gif';
import sleep from '../../../assets/pika/sleep.gif';
import pikaSoundBucket from './pikaSoundBucket';

const sleepClicks = 6;

const pikaSounds = pikaSoundBucket.map(
    soundFile =>
        new UIfx(soundFile, {
            volume: 0.9,
            throttleMs: 300,
        })
);

const Pika = () => {
    const [clicks, setClicks] = useState(0);
    const sleepy = clicks >= sleepClicks;
    const onLastClick = clicks === sleepClicks - 1;

    const getRandomSound = () => {
        const index = Math.floor(Math.random() * pikaSounds.length);
        return pikaSounds[index];
    };

    return (
        <Box
            width="80%"
            maxWidth="600px"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
                if (!sleepy) {
                    if (!onLastClick) {
                        getRandomSound().play();
                    }
                    setClicks(clicks + 1);
                }
            }}
        >
            <Image src={sleepy ? sleep : construction} width="100%" />
            {sleepy && (
                <Text textAlign="center" fontSize={[3, 4, 5]}>
                    Pikachu is sleepy, come back later
                </Text>
            )}
        </Box>
    );
};

export default Pika;
