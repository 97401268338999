import React, { useState } from 'react';
import { Text } from 'rebass';

const Schedule = () => {
    const [showVideo, setShowVideo] = useState(false);

    if (!showVideo) {
        return (
            <Text
                fontSize={[2, 3, 4]}
                fontWeight={700}
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowVideo(true)}
            >
                (Open Bar Dude!)
            </Text>
        );
    }

    return (
        <iframe
            src="https://www.youtube.com/embed/eZNcAdl7lD0?autoplay=1&mute=0"
            title="open-bar-dude"
            width="420"
            height="315"
            frameBorder="0"
            allow="autoplay"
        />
    );
};

export default Schedule;
